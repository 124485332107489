import React, { useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios, { Axios, AxiosResponse } from "axios";
import axiosInstansce from "../../../utils/AxiosInstance";
import { itProfil } from "./MyProfil";

const AccountSecurity: React.FC = () => {
    const navigation = useNavigate();
    const [data, setData] = useState<itProfil>();
    const _getProfil = () => {
        axiosInstansce.get('user')
            .then((respon: AxiosResponse<any, any>) => {
                setData(respon.data.data);
            })
    }
    useEffect(() => {

        return () => {
            _getProfil();
        };
    }, []);
    return (<>
        <Navbar title="Account & Security" />
        <Height height={80} />
        <div className=" divide-y divide-gray-100">
            <div onClick={() => {
                navigation('/setting/account-security/my-profil');
            }} className="p-4 flex active:bg-slate-100">
                <div className=" flex-auto">My Profile </div> <FaChevronRight />
            </div>

            <div onClick={() => {
                navigation('/setting/account-security/ganti-handphone')
            }} className="p-4 flex">
                <div className=" flex-auto active:bg-slate-100" >Phone Numeber </div> {data?.phone}
            </div>
            <div
                onClick={() => {
                    navigation('/setting/account-security/ganti-email')
                }}
                className="p-4 flex  active:bg-slate-100">
                <div className=" flex-auto">Email </div> {data?.email}
            </div><div
                onClick={() => {
                    navigation('/setting/account-security/change-password')
                }}
                className="p-4 flex  active:bg-slate-100">
                <div className=" flex-auto">Change Password </div>
                <FaChevronRight />
            </div>

        </div>
    </>);
}

export default AccountSecurity;