import React from "react";
import Navbar from "../../mobile-componen/Navbar";
import Height from "../../mobile-componen/Height";

const NotifikasiPage: React.FC = () => {
    return (<>
        <Navbar action={true} title="Notification" />
        <Height height={80} />
        <div className="p-4">
            <table cellPadding={4} width={"100%"}>
                {[...Array(4)].map((list) => (
                    <tr>
                        <td>
                            <div style={{
                                height: "80px",
                                width: "80px",
                                display: "flex",
                                alignItems: "center",
                                color: "#FFF",
                                fontSize: "14px",
                                textAlign: "center",
                                justifyContent: "center"
                            }} className=" bg-orange-500 p-2 font-bold rounded">SPECIAL FOR YOU</div>
                        </td>
                        <td width={"5px"}></td>
                        <td valign="top">
                            <div style={{ fontWeight: "bold" }}>Diskon Untuk produk Favoritmu</div>
                            <div style={{ fontSize: "12px" }}>😍 Dapatkan DISKON untuk membeli produk yang kamu mau! Check Out 👉</div>
                        </td>
                    </tr>
                ))}

            </table>
        </div>
        <div className=" bg-slate-100 py-3  opacity-80 px-4 flex">
            <div className="flex-auto">
                Status Pesanan
            </div>
            <span className=" btn-link decoration-inherit text-orange-400">Tandai Sudah Di baca</span>
        </div>
        <div className="p-2">
            <table cellPadding={10}>
                <tbody>
                    {[...Array(3)].map((list) => (
                        <tr>
                            <td width={"70px"}>
                                <div style={{

                                    width: "60px", /* Sesuaikan lebar container sesuai kebutuhan */
                                    height: "60px", /* Sesuaikan tinggi container sesuai kebutuhan */
                                    overflow: "hidden"
                                }} className="square-container rounded">
                                    <img style={{ width: "60px", height: "60px", objectFit: "cover" }} src="https://dev-olshop.berkatsoft.com/image/product/6544a185d2f56.jpg" />
                                </div>

                            </td>
                            <td>
                                <div style={{}}>
                                    Pesanan Di serahkan ke jasa kirim
                                </div>
                                <div className=" opacity-55">Pesanan akan diproses. Cek rincian dan lacak pesanan anda</div>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    </>);
}

export default NotifikasiPage;