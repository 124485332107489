import React, { useEffect } from "react";
import Height from "../../../mobile-componen/Height";
import Navbar from "../../../mobile-componen/Navbar";

const NotifikasiApp: React.FC = () => {
    useEffect(() => {

        return () => {
            document.body.style.background = "#F9F9F9";
        }
    }, [])
    return (<>
        <Navbar title="Notifikasi In The App" />
        <Height height={90} />
        adfs

    </>);
}

export default NotifikasiApp;