import './banner.scoped.scss'
import './banner.css'
import React, { useEffect, useState } from 'react';
import Api from '../../../../../utils/Api';
import { itBanner } from "../../../../../interface/itBanner"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
/// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import baseUrl from '../../../../../config/baseUrl';
import { IconShieldSearch } from '@tabler/icons-react';
import { FaChevronLeft, FaSearch, FaShoppingCart } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const BannerComponent: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<itBanner[]>([]);
    const _loadBanners = () => {
        Api.get("/banner").then(res => {
            setData(res.data.data);
        });
    };
    useEffect(() => {
        _loadBanners();
    }, []);
    return (<>
        <Swiper
            pagination={{
                dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
        >

            {data[4]?.images.map((list, index) => (
                <SwiperSlide>
                    <img style={{ width: "100%" }} src={list} />
                </SwiperSlide>
            ))}



        </Swiper>

        <div style={{ position: "absolute", top: "65px", zIndex: 99999, left: "20px", paddingLeft: "0px" }}>
            <table width={"100%"}>
                <tbody>
                    <tr>
                        <td valign='middle'>
                            <button onClick={() => {
                                navigate('/');
                            }} className='btn'><FaChevronLeft color='#FFFFFF' /></button>
                        </td>
                        <td valign="middle" width={"90%"}>
                            <button style={{ padding: "10px", width: "100%", background: "#FFF", fontSize: "10px", borderRadius: "20px", border: "none" }} className='flex'><FaSearch style={{ marginTop: "2px" }} /> <span className='flex-auto'>Search by brand, categori, product name</span></button>
                        </td>
                        <td valign='middle' style={{ textAlign: "right" }}>
                            <button onClick={() => {
                                navigate('/shopping/cart')
                            }} className='btn'><FaShoppingCart color='#FFF' size={24} /> </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>);
}

export default BannerComponent;