import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./profile.scoped.scss";
import "./header.css";
import NoPhotoImage from "./../../../images/icons/no-photo.png";
import AvatarComponent from "../../../components/pages/profile/index/AvatarComponent";
import { AuthUserContext } from "../../../context/AuthUserContext";
import {
    IconBoxSeam,
    IconChevronRight,
    IconClipboardList,
    IconHeart,
    IconLogout,
    IconMapPin,
    IconShoppingCart,
    IconStarFilled,
    IconTicket,
    IconTruckDelivery,
    IconUser,
    IconWallet
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import Height from "../../../mobile-componen/Height";
import { useTranslation } from "react-i18next";
import ShoppingCart from "../../shopping/cart/ShoppingCart";
import { FaCog, FaCogs, FaShoppingCart } from "react-icons/fa";

export default function ProfileIndex() {
    /**
     * Hooks
     *
     */
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    /**
     * Context
     *
     */
    const { user } = useContext(AuthUserContext);

    useEffect(() => {
        // Create a new meta element
        const metaThemeColor = document.createElement("meta");

        // Set its attributes
        metaThemeColor.setAttribute("name", "theme-color");
        metaThemeColor.setAttribute("content", "#FFFFFF"); // Change this to the desired color

        // Append it to the document head
        document.head.appendChild(metaThemeColor);

        // Clean up function to remove the meta tag when component unmounts
        return () => {
            document.head.removeChild(metaThemeColor);
        };
    }, []);
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <div style={{ marginTop: "-130px" }}>
                <div style={{ width: "100%" }} className="theader">
                    <div style={{ textAlign: "right" }}>
                        <Height height={60} />
                        <table align="right">
                            <tbody>
                                <tr>
                                    <td>
                                        <button className="btn">
                                            <FaShoppingCart color="#FFF" />
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                navigate("/setting");
                                            }}
                                            className="btn"
                                        >
                                            <FaCog color="#FFF" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Height height={40} />
                        <div>
                            <table>
                                <tr>
                                    <td>
                                        <img
                                            style={{ width: "40px", borderRadius: "50%" }}
                                            src={NoPhotoImage}
                                            alt={user.name}
                                        />
                                    </td>
                                    <td width={"10px"} />
                                    <td>
                                        <span style={{ color: "#FFF", fontSize: "13px" }}>{user.name}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <Height height={10} />
                    </div>
                </div>

                <div className="profile-index-page">
                    <div className="profile-wrapper">
                        <div className="order">
                            <IconClipboardList color="#081CC9" size={16} />
                            <div>Pesanan Saya</div>
                            <Link>
                                View All <IconChevronRight size={22} />
                            </Link>
                        </div>
                        <div className="icon-menu">
                            <Link>
                                <IconWallet /> Unpaid
                            </Link>
                            <Link>
                                <IconBoxSeam /> On Process
                            </Link>
                            <Link>
                                <IconTruckDelivery /> On Going
                            </Link>
                            <Link>
                                <IconStarFilled /> Rate
                            </Link>
                        </div>
                        <div className="links">
                            <Link to={"account"}>
                                <IconUser size={18} color="#081CC9" /> My Account <IconChevronRight color="#FFAC33" />
                            </Link>
                            <Link to={"orders"}>
                                <IconTruckDelivery size={18} color="#E4A951" /> Orders{" "}
                                <IconChevronRight color="#FFAC33" />
                            </Link>
                            <Link to={"tickets"}>
                                <IconTicket size={18} color="#13791b" /> {t("tickets")}{" "}
                                <IconChevronRight color="#FFAC33" />
                            </Link>
                            <Link to={"wishlist"}>
                                <IconHeart size={18} color="#F24E1E" /> Wishlist <IconChevronRight color="#FFAC33" />
                            </Link>
                            <Link to={"address"}>
                                <IconMapPin size={18} color="#F24E1E" /> Address
                                <IconChevronRight color="#FFAC33" />
                            </Link>
                            <Link to={"/account/vendor/productinformation"}>
                                <IconShoppingCart size={18} color="#db1ef2" /> {t("consigner")}
                                <IconChevronRight color="#FFAC33" />
                            </Link>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    const c = window.confirm("Apakah anda ingin logout dari?");
                                    if (c) {
                                        window.localStorage.clear();
                                    }
                                    navigate("/");
                                }}
                                className="link btn btn-link inline"
                            >
                                <IconLogout color="#111" size={18} /> Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
