import { useEffect, useContext } from "react";
import { LoadingContext } from "../../../context/LoadingContext";
import Api from "../../../utils/Api";
import { useNavigate } from "react-router-dom";

export default function AuthGoogleIndex() {
    /**
     * Context
     *
     */
    const navigate = useNavigate();
    const { setLoading } = useContext(LoadingContext);

    useEffect(() => {
        setLoading(true);
        Api.get(`/auth/callback${window.location.search}&provider=google`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        })
            .then(response => {
                return response.data;
            })
            .then(data => {
                localStorage.setItem("apiToken", data.data.token);
                window.location = "http://azwar.com";
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false));
    }, []);

    return <div />;
}
