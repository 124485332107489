import { useContext, useEffect, useState } from "react";
import BreadCrumbComponent from "../../../components/general/breadcrumb/BreadCrumbComponent";
import ContainerComponent from "../../../components/general/container/ContainerComponent";
import TermAndConditionIllustration from "./../../../images/term-and-condition/TermAndConditionIllustration.png";
import "./term-and-condition.scoped.scss";
import "./term-and-confition.css";
import Api from "../../../utils/Api";
import { useLocation } from "react-router-dom";
import { LoadingContext } from "../../../context/LoadingContext";
import { LanguageContext } from "../../../context/LanguageContext";
import Navbar from "../../../mobile-componen/Navbar";

export default function TermAndConditionIndex() {
    /**
     * Hooks
     *
     */
    const { pathname } = useLocation();

    /**
     * Context
     *
     */
    const { setLoading } = useContext(LoadingContext);
    const { language } = useContext(LanguageContext);
    const suffix = language == "id" ? "" : "_en";

    /**
     * Main State
     *
     */
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [contactObj, setContactObj] = useState({});
    const [termConditionObject, setTermConditionObject] = useState({});

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        setLoading(true);

        loadBreadcrumb();
        loadContact();
        loadTermCondition();
    }, []);

    useEffect(() => {
        if (Object.keys(contactObj).length > 0 && Object.keys(termConditionObject).length > 0) {
            setLoading(false);
        }
        setBreadcrumb([
            {
                label: "Home",
                url: "/"
            },
            {
                label: termConditionObject["title" + suffix]
            }
        ]);
    }, [termConditionObject, contactObj]);

    useEffect(() => {
        setBreadcrumb([
            {
                label: "Home",
                url: "/"
            },
            {
                label: termConditionObject["title" + suffix]
            }
        ]);
    }, [language]);

    const loadTermCondition = () => {
        Api.get("/term-condition").then(res => {
            if (res) {
                console.log(res.data.data[0]);
                setTermConditionObject(res.data.data[0]);
            }
        });
    };

    const loadContact = () => {
        Api.get("/contact").then(res => {
            setContactObj(res.data.data[0]);
        });
    };

    const loadBreadcrumb = () => {
        setBreadcrumb([
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Term and Conditions"
            }
        ]);
    };

    return (
        <>
            <Navbar title="Term and Conditions" />
            <div className="p-4">
                <div className="body">
                    <p dangerouslySetInnerHTML={{ __html: termConditionObject["description" + suffix] }} />
                </div>
            </div>
        </>
    );
}
