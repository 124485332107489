import React, { useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Switch from 'react-switch';

const NotifikasiSetting: React.FC = () => {
    const [notifApp, setNotifApp] = useState(false);
    const [notifEmail, setNotifEmail] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {

        return () => {
            document.body.style.background = "#F9F9F9";
        }
    }, [])
    return (<>
        <Navbar title="Notifikasi Setting" />
        <Height height={90} />
        <div className=" divide-y-2 divide-blue-100">
            <div className="p-3 flex bg-white">
                <div className="flex-auto"> Notification In The App</div>
                <Switch onChange={() => setNotifApp(!notifApp)} checked={notifApp} />
            </div>
            <div className="p-3 flex bg-white">
                <div className="flex-auto">Email Notification</div>
                <Switch onChange={() => setNotifEmail(!notifEmail)} checked={notifEmail} />
            </div>
        </div>
    </>);
}

export default NotifikasiSetting;