import React, { useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";
import Switch from "react-switch";
const PrivacySetting: React.FC = () => {
    const [permissionFoto, setPermissonFoto] = useState(false);
    const [permissionCamera, setPermissonCamera] = useState(false);
    const [permissionLocation, setPermissonLocation] = useState(false);
    useEffect(() => {

        return () => {
            document.body.style.background = "#F9F9F9";
        }
    }, [])
    return (<>


        <Navbar title="Privasi Setting" />
        <Height height={100} />

        <div className="">
            <div className=" p-3 flex  bg-white">
                <div className="flex-auto">
                    Allow the app to access location information
                </div>
                <Switch onChange={(e) => {
                    setPermissonLocation(!permissionLocation);
                }} checked={permissionLocation} />

            </div>
            <div className="p-3" style={{ opacity: "0.5", }}>Grant access to determine the location of your address</div>
        </div>
        <div className="">
            <div className=" p-3 flex  bg-white">
                <div className="flex-auto">
                    Allow the app to access photo album
                </div>
                <Switch onChange={(e) => {
                    setPermissonFoto(!permissionFoto);
                }} checked={permissionFoto} />

            </div>
            <div className="p-3" style={{ opacity: "0.5", }}>
                Grant access to be able to upload photos for feeds or ratings
            </div>
        </div>
        <div className="">
            <div className=" p-3 flex  bg-white">
                <div className="flex-auto">
                    Allow the app to access camera
                </div>
                <Switch onChange={(e) => {
                    setPermissonCamera(!permissionCamera);
                }} checked={permissionCamera} />

            </div>
            <div className="p-3" style={{ opacity: "0.5", }}>
                Grant access to be able to a photo and video
            </div>
        </div>


    </>);
}

export default PrivacySetting;