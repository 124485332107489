import { useEffect, useState } from "react";
import Api from "../../../utils/Api";
import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import "./text.css"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';
import { FaSearch } from "react-icons/fa";
import Height from "../../../mobile-componen/Height";
import { useNavigate } from "react-router-dom";
type itHasil = {
    id: number,
    name: string,
    price: number,
    name_event: string,
    img: string,
};
export default function EventIndex() {

    const navigate = useNavigate();
    const [data, setData] = useState<itEvent[]>([]);
    const [listData, setListData] = useState<itHasil[]>([]);
    const loadEvents = () => {

        Api.get("/event").then(res => {
            setData(res.data.data);
            const a: itEvent[] = res.data.data;
            const hasil: itHasil[] = [];
            a.map((list, index) => {
                const h = list.details;
                h.map((c, b) => {
                    hasil.push({
                        id: list.id,
                        name: list.name,
                        price: Number(c.price),
                        name_event: c.name,
                        img: c.event.cover_image,
                    })
                })

            })
            setListData(hasil);
        });
    };

    const mapDataUlang = () => {

    }

    useEffect(() => {
        loadEvents();
        mapDataUlang();
    }, [])
    return <>
        <div style={{ position: "fixed", zIndex: "100", top: "50px", width: "100%", padding: "10px" }}>
            <button className="btn flex" style={{ textAlign: "left", background: "#FFF", border: "1px solid #DFDFDF", width: "100%" }}>
                <FaSearch color="orange" style={{ paddingTop: "6px" }} /> <span className="flex-auto pl-2">Search by Event Name, Location</span>
            </button>
        </div>
        <div style={{ marginTop: "-110px" }}>
            <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                {data.map((list, index) => (
                    <SwiperSlide>
                        <img style={{ width: "100%", height: "260px" }} src={list.banner_image} />
                        <div id="text" style={{
                            height: "80px",
                            marginTop: "-100px", padding: "10px",
                            fontWeight: "bold", color: "white",
                            fontSize: "20px", width: "50%",
                        }}>{list.name}</div>


                    </SwiperSlide>
                ))}

            </Swiper>
            <Height height={50} />

            <div className="container">
                <div className="row">

                    {listData.map((list, index) => (
                        <div className="col-6" onClick={() => {
                            navigate('/event/' + list.id)
                        }} style={{ marginBottom: "10px" }}>
                            <div style={{ border: "1px solid #DFDFDF", padding: "5px", borderRadius: "5px" }}>
                                <div style={{ width: "100%", height: "175px", overflow: "hidden" }}>
                                    <img style={{ width: "100%", height: "175px", borderRadius: "10px" }} src={list.img} />
                                </div>

                                <Height height={10} />
                                <div>{list.name}</div>

                                <Height height={10} />
                                <div style={{ fontSize: "14px", textDecoration: "line-through", opacity: "0.5" }}>Rp.{(list.price * 1.6).toLocaleString()}/ Day</div>
                                <div style={{ color: "orange", fontSize: "14px" }}>Rp.{list.price.toLocaleString()}/ Day</div>
                            </div>
                        </div>

                    ))}

                </div>
            </div>
        </div>

    </>;
}
