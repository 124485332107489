import React from "react";
import { FaArrowLeft, FaSearch, FaShoppingCart } from 'react-icons/fa';
import { Navigate, useNavigate } from "react-router-dom";
import Height from "./Height";
type itNavbar = {
    title: string;
    back?: string;
    action?: boolean

}
const Navbar: React.FC<itNavbar> = ({
    title, back = "", action = false }) => {
    const navigate = useNavigate();
    return (<>
        <nav style={{ background: "#fff", position: "fixed", zIndex: "300", left: "0px", top: "0px", width: "100%", padding: "10px", borderBottom: "5px solid #FAFAFA" }}>
            <div>
                <Height height={30} />
                <table width={"100%"}>
                    <tbody>
                        <tr>
                            <td width={"30px"}>
                                <button
                                    onClick={() => {
                                        back == "" ? navigate(-1) : navigate(back);
                                    }} className="btn">
                                    <FaArrowLeft />
                                </button>
                            </td>
                            <td width="20px"></td>
                            <td>{title}</td>
                            {action && <>
                                <td width={"40px"} align="right">
                                    <button onClick={() => {
                                        navigate('/shopping/cart')
                                    }} className="btn active:opacity-40">
                                        <FaShoppingCart />
                                    </button>
                                </td><td width={"40px"} align="right">
                                    <button onClick={() => {
                                        navigate('/menu-utama')
                                    }} className="btn active:opacity-45">
                                        <FaSearch />
                                    </button>
                                </td>
                            </>}

                        </tr>
                    </tbody>
                </table>
            </div>
        </nav>
    </>);
}

export default Navbar;