import React, { useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";

import "./input.css";
import axios, { AxiosResponse } from "axios";
import baseUrl from "../../../config/baseUrl";
import axiosInstansce from "../../../utils/AxiosInstance";
import itKecamatan from "../../../interface/itKecamatan";

import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaChevronCircleRight } from "react-icons/fa";
import Checkbox from "rc-checkbox";
import { useNavigate } from "react-router-dom";
const TambahAlamat: React.FC = () => {

    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [address, setAddres] = useState<string>('');
    const [tag, setTag] = useState<string>('');
    const [pKecamatan, setPKecamatan] = useState<{
        id: string, label: string
    }>({
        id: "", label: ""
    })
    const _save = (e: any) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('address', address);
        formData.append('phone', phone);
        formData.append('tag', mark);
        formData.append('subdistrict_id', pKecamatan?.id);
        formData.append('is_primary', isDefault ? "true" : "false");

        axiosInstansce.post('address',
            formData
        )
            .then((respon: AxiosResponse<any, any>) => {
                if (respon.data.message == "success") {
                    alert('Data berhasil di simpan');
                    navigate(-1);
                }
            });

    }
    const [kecamatan, setKecamatan] = useState<itKecamatan[]>([]);
    const [isLoading, setLoading] = useState(false);
    const getKecamatan = () => {
        if (cari !== "") {
            setLoading(true);
            axiosInstansce.get('kecamatan?name=' + cari)
                .then((respon: AxiosResponse<any, any>) => {
                    setKecamatan(respon.data.data);
                    setLoading(false);
                })
        }

    }
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [cari, setCari] = useState<string>('');
    useEffect(() => {
        const carix = setTimeout(() => {
            setLoading(false);
            if (cari !== "")
                getKecamatan();
        }, 2000)
        return () => clearTimeout(carix);
    }, [cari])
    const [mark, setMark] = useState<string>("Office");
    return (<>
        <Navbar title="Create Address" />
        <Height height={90} />
        <form onSubmit={_save}>


            <div className="p-3 bg-slate-200">
                Contact
            </div>
            <div>
                <input
                    required
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                    id="cInput" type="text"

                    placeholder="Full Name" />
            </div>

            <div style={{ borderTop: "1px solid #DFDFDF" }}>
                <input
                    required
                    minLength={9}
                    maxLength={16}
                    onChange={(e) => {
                        setPhone(e.target.value);
                    }}
                    id="cInput" type="tel"

                    placeholder="Nomor handphone" />
            </div>
            <div className="p-3 bg-slate-200">
                Address
            </div>
            <div className=" divide-y-2">
                <textarea id="cInput"
                    required
                    onChange={(e) => {
                        setAddres(e.target.value);
                    }}

                    placeholder="Full address" ></textarea>
            </div>
            <Height height={20} />
            <div style={{ borderTop: "1px solid #dfdfdf" }} className="flex p-2">
                <div className="flex-auto">Mark as </div>
                <div>
                    <button onClick={() => {
                        setMark('Office')
                    }}
                        type="button"
                        {...mark == "Office" && { style: { background: "#DFDFDF" } }} className={"px-1 rounded border-2 border-inherit border-amber-500"}>Office</button>
                    {" "}
                    <button
                        type="button"
                        onClick={() => {
                            setMark('Home')
                        }}
                        {...mark == "Home" && { style: { background: "#DFDFDF" } }}
                        className="px-1 rounded border-2 border-inherit border-amber-500">Home</button>
                </div>
            </div>
            <div onClick={() => {
                handleShow();
            }} style={{ borderTop: "1px solid #dfdfdf" }} className="p-3">
                <span style={{ opacity: "0.5" }}>{pKecamatan.label == "" ? "Ketikkan nama kecamatan...." : pKecamatan.label}</span>
            </div>
            <div style={{ borderTop: "1px solid #dfdfdf" }} className="p-3">
                <label onClick={() => {
                }} className="flex"><span className="flex-1">Gunakan sebagai alamat utama</span>
                    <input {...isDefault && { checked: true, }} style={{ marginTop: "5px" }} type="checkbox" /></label>

            </div>
            <div className="p-3">
                <button style={{ width: "100%" }} className="btn btn-success">Simpan Data</button>
            </div>

        </form >

        <Offcanvas show={show} onHide={handleClose}>
            <Height height={50} />
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Pilih Kelurahan</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <input onChange={(e) => {
                    if (e.target.value !== "")
                        setCari(e.target.value);
                }} autoFocus type="search" placeholder="Ketikkan nama kelurhana..." className="form-control" />
                {isLoading && <div className="flex justify-center align-middle" style={{ textAlign: "center" }}>
                    <img style={{ width: "20px", marginRight: "10px", }} src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator.gif" /> {" "}Mencari data...
                </div>}

                <div style={{ height: "80vh" }}>
                    <ul className="divide divide-y">
                        {kecamatan.map((list, index) => (
                            <li onClick={() => {
                                handleClose();
                                setPKecamatan({ id: String(list.id), label: list.fullname });

                            }} className="p-2 border-spacing-1 flex"><span className="flex-1">{list.fullname}</span></li>
                        ))}
                    </ul>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    </>);

}

export default TambahAlamat;