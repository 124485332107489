import React, { FormEvent, useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";
import axiosInstansce from "../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";

export type itProfil = {
    name: string;
    gender: string;
    birthDate: string;
    email: string;
    no_hp: string;
    phone: string;
    userName: string
}
const MyProfil = () => {

    const [email, setEMail] = useState('');
    const [no_hp, setNo_hp] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('male');

    const [name, setName] = useState<string>('');
    const [birdhdate, setBirdhdate] = useState('')


    const [isLoading, setLoading] = useState(false);
    const _getProfil = () => {
        axiosInstansce.get('/user')
            .then((respon: AxiosResponse<any, any>) => {
                const data: itProfil = (respon.data.data);
                setName(data.name);
                setJenisKelamin(data.gender)
                setBirdhdate(data.birthDate)
                setEMail(data.email)
                setNo_hp(data.no_hp)
            })
    }

    const _update = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        axiosInstansce.post('user/update', {
            name: name,
            birth_date: birdhdate,
            gender: jenisKelamin,
            email: email,
            no_hp: no_hp,

        }).then((respon: AxiosResponse<any, any>) => {
            if (respon.data.message == "success") {
                alert('Profil berhasil di update');

            }
            setLoading(false);
        })
    }

    useEffect(() => {

        return () => {
            _getProfil()
        };
    }, []);
    return (<>
        <Navbar title="My Profil" />
        <Height height={80} />
        <div className="bg-white  rounded-lg">
            <form onSubmit={_update}>
                <div className="divide-y divide-gray-200">
                    <div className="p-2 flex">
                        <label className=" pr-2 p-2" htmlFor="">Nama</label>
                        <input
                            required
                            onChange={(e) => {
                                setName(e.target.value);
                            }}

                            value={name} className="p-2 flex-auto" id="cInput" style={{ textAlign: "right" }} placeholder="Nama Profil..." type="text" />
                    </div>
                    <div className="p-2 flex">
                        <label className=" pr-2 p-2" htmlFor="">Jenis Kelamin</label>
                        <div className="flex-auto" style={{ textAlign: "right" }}>
                            <button onClick={() => {
                                setJenisKelamin('male')
                            }}
                                type="button" className={(jenisKelamin == "male") ? "btn  btn-sm btn-primary" : "btn btn-sm"}> Pria</button>
                            {" "}
                            <button
                                onClick={() => {
                                    setJenisKelamin('female')
                                }}
                                type="button" className={(jenisKelamin == "female") ? "btn  btn-sm btn-primary" : "btn btn-sm"}>Wanita</button>
                        </div>
                    </div>
                    <div className="p-2 flex">
                        <label className=" pr-2 p-2" htmlFor="">Tanggal Lahir</label>
                        <input
                            required
                            onChange={(e) => {
                                setBirdhdate(e.target.value);
                            }} value={birdhdate} className="p-2 flex-auto" id="cInput" style={{ textAlign: "right" }} placeholder="Nama Profil..." type="date" />
                    </div>
                    <div className="p-4">
                        <button style={{ width: "100%" }} className="btn btn-block btn-primary" {...isLoading && { disabled: true }}>Update Profil</button>
                    </div>

                </div>

            </form >
        </div >
    </>);
}

export default MyProfil;