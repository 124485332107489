import { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../../components/general/breadcrumb/BreadCrumbComponent";
import ContainerComponent from "../../../components/general/container/ContainerComponent";
import BannerComponent from "../../../components/pages/event/show/banner/BannerComponent";
import EventDescriptionComponent from "../../../components/pages/event/show/event-description/EventDescriptionComponent";
import TicketPurchaseComponent from "../../../components/pages/event/show/ticket-purchase/TicketPurchaseComponent";
import AdditionalDetailComponent from "../../../components/pages/event/show/additional-detail/AdditionalDetailComponent";
import "./event-show.scss";
import Api from "../../../utils/Api";
import { useParams, useLocation } from "react-router-dom";
import { LoadingContext } from "../../../context/LoadingContext";
import { LanguageContext } from "../../../context/LanguageContext";
import { FaArrowLeft, FaCalendar, FaClock, FaMinus, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function EventShow() {
    const { pathname } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    /**
     * Context
     *
     */
    const { setLoading } = useContext(LoadingContext);
    const { language } = useContext(LanguageContext);

    /**
     * Main States
     *
     */
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [eventDetailObj, setEventDetailObj] = useState({});

    useEffect(() => {
        loadBreadcrumbs();
        loadEventDetailObj(id);
    }, []);

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const loadBreadcrumbs = () => {
        setBreadcrumbs([
            {
                label: language == "id" ? "Beranda" : "Home",
                url: "/"
            },
            {
                label: language == "id" ? "Acara" : "Event",
                url: "/event"
            },
            {
                label: eventDetailObj.name
            }
        ]);
    };

    useEffect(() => {
        loadBreadcrumbs();
    }, [language, eventDetailObj]);

    const loadEventDetailObj = id => {
        if (id) {
            setLoading(true);
            Api.get("/event/" + id).then(res => {
                setEventDetailObj(res.data.data);
                setLoading(false);
            });
        }
    };

    return (
        <>
            <nav
                style={{
                    padding: "10px",
                    background: "#FFF",
                    position: "fixed",
                    left: "0px",
                    top: "0px",
                    width: "100%"
                }}
            >
                <div style={{ height: "30px" }} />
                <table>
                    <tr>
                        <td valign="middle">
                            <button
                                onClick={() => {
                                    navigate("/event");
                                }}
                                className="btn"
                            >
                                <FaArrowLeft />
                            </button>
                        </td>
                        <td width={"10px"} />
                        <td valign="middle">Detail Event</td>
                    </tr>
                </table>
            </nav>
            <div style={{ marginTop: "-90px" }} className="event-show-container">
                <ContainerComponent />
                <BannerComponent eventDetailObj={eventDetailObj} />
                <ContainerComponent>
                    <EventDescriptionComponent eventDetailObj={eventDetailObj} />
                </ContainerComponent>
                <TicketPurchaseComponent eventDetailObj={eventDetailObj} />
                <AdditionalDetailComponent />
            </div>
        </>
    );
}
