import React, { Component, useContext } from "react";
import { IconHeadset, IconInfoCircleFilled, IconShoppingBag, IconTag } from "@tabler/icons-react";
import ContainerComponent from "../general/container/ContainerComponent";
import "./footer.scoped.scss";
import { AuthUserContext } from "../../context/AuthUserContext";
import { SiteSettingContext } from "../../context/SiteSettingContext";
import { useTranslation } from "react-i18next";
import IconAppStore from "./../../images/App_Store_(iOS).svg";
import IconGooglePlay from "./../../images/1664285914google-play-logo-png.png";

export default function FooterComponent() {
    const { user } = useContext(AuthUserContext);
    const { siteSetting } = useContext(SiteSettingContext);
    const { t } = useTranslation();

    return (
        <>
        
        </>
      
    );
}
