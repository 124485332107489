import React, { useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";
import { IconCheckbox } from "@tabler/icons-react";

const Language: React.FC = () => {
    const [reload, setReload] = useState<number>(0);
    const [lang, setLang] = useState<string>();
    useEffect(() => {

        return () => {
            const c: string | null = typeof window !== null ? window.localStorage.getItem('selectedLanguage') : "";
            setLang(c != null ? c : "");
        };
    }, [reload]);
    return (<>
        <Navbar title="Language" />
        <Height height={90} />

        <div className="devide-y  font-bold divide-indigo-300 ">
            <div
                onClick={() => {
                    window.localStorage.setItem('selectedLanguage', 'id')

                    setReload(reload + 1);
                }}
                {...lang == "id" ? { style: { opacity: 1 } } : { style: { opacity: 0.3 } }} className="p-3 flex active:bg-red-100">
                <div className="flex-auto"> Bahasa Indonesia </div>
                <IconCheckbox color="green" />
            </div>
            <div onClick={() => {
                window.localStorage.setItem('selectedLanguage', 'en')
                setReload(reload + 1);
            }} {...lang == "en" ? { style: { opacity: 1 } } : { style: { opacity: .3 } }} className="p-3 flex active:bg-red-100">
                <div className="flex-auto"> English </div>
                <IconCheckbox />
            </div>
        </div>
    </>);
}

export default Language;