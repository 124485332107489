import axios, { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import baseUrl from "../../../../../config/baseUrl";
import { itProduct } from "../../../../../interface/itProduct";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';
import Height from "../../../../../mobile-componen/Height";
import { Link, useNavigate } from "react-router-dom";
const ProductLainnya: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<itProduct[]>([]);
    const getData = () => {
        axios.get(baseUrl('api/product'))
            .then((respon: AxiosResponse<any, any>) => {
                setData(respon.data.data);
            })
    }
    useEffect(() => {
        getData();
    }, [])
    return (<>
        <Height height={20} />
        <table style={{ fontWeight: "bold" }} width={"100%"}>
            <tr>
                <td>Other Product</td>
                <td style={{ textAlign: "right" }} width={"150px"}>
                    {/* <Link to={"#"}>View All</Link> */}
                </td>
            </tr>
        </table>
        <Height height={20} />
        <Swiper
            slidesPerView={'auto'}
            spaceBetween={30}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
        >
            {
                data.map((list, index) => (
                    <SwiperSlide onClick={() => {
                        navigate('/shop/' + list.id);
                    }} style={{ width: "60%" }}>
                        <img style={{ width: "100%", borderRadius: "10px" }} src={list.images[0]} />
                        <div style={{ fontWeight: "bold" }}>{list.name}</div>
                        <div>Rp. {Number(list.sale_price).toLocaleString()}</div>
                        <div>{list.brand}</div>
                    </SwiperSlide>
                ))
            }

        </Swiper>
    </>);
}

export default ProductLainnya;