import { useContext, useEffect, useState } from "react";
import "./form-book.scoped.scss";
import { AuthUserContext } from "../../../../../context/AuthUserContext";
import { CurrencyContext } from "../../../../../context/CurrencyContext";
import { LoadingContext } from "../../../../../context/LoadingContext";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { IconCircleX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import Api from "../../../../../utils/Api";

const PHONE_NUMBER_CODE = ["+62", "+1"];

export default function FormBookComponent({ event, ticketId, setActivedIndexState, user }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currency } = useContext(CurrencyContext);
    const formater = new Intl.NumberFormat(currency == "id" ? "id-ID" : "en-EN", {
        style: "currency",
        currency: currency == "id" ? "IDR" : "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });

    const { refreshUser } = useContext(AuthUserContext);
    const { setLoading } = useContext(LoadingContext);

    const [fullName, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [phonePrefix, setPhonePreix] = useState("");
    const [message, setMessage] = useState("");

    const [amountTicket, setAmountTicket] = useState(0);
    const [ticketObj, setTicketObj] = useState({});
    const [showModalUpdateProfile, setShowModalUpdateProfile] = useState(false);

    const [phonePrefixCreateAddress, setPhonePrefixCreateAddress] = useState("+62");
    const [phoneCreateAddress, setPhoneCreateAddress] = useState("");
    const [gender, setGender] = useState("");
    const [birthDate, setBirthDate] = useState("");

    useEffect(() => {
        if (user) {
            const { name, email, phone, gender, birthDate } = user;

            setBirthDate(birthDate);
            setGender(gender);
            setFullname(name);
            setEmail(email);
            for (const phoneCode of PHONE_NUMBER_CODE) {
                if (phone?.startsWith(phoneCode)) {
                    setPhone(phone.substring(phoneCode.length));
                    setPhonePreix(phoneCode);
                    return;
                }
            }
            setPhonePreix(PHONE_NUMBER_CODE[0]);
        }
    }, [user]);

    useEffect(() => {
        setShowModalUpdateProfile(phone == "" || !gender);
    }, [phone]);

    useEffect(() => {
        setAmountTicket(localStorage.getItem("amount_ticket"));

        if (event.details) {
            setTicketObj(event.details[event.details.findIndex(val => val.id == ticketId)]);
        }
    }, [event]);

    const doUpdateProfile = () => {
        setLoading(true);

        const formData = new FormData();
        formData.append("user_name", user.userName);
        formData.append("name", user.name);
        formData.append("no_hp", `${phonePrefixCreateAddress ?? PHONE_NUMBER_CODE[0]}${phoneCreateAddress}`);
        formData.append("gender", gender);
        formData.append("birth_date", birthDate);
        formData.append("email", user.email);

        Api.post("/user/update", formData, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("apiToken")
            }
        })
            .then(res => {
                refreshUser();
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="form-container">
                {/* Modal Confirm */}
                <Modal centered show={showModalUpdateProfile}>
                    <Modal.Header>
                        <Modal.Title>{t("editprofilefirstbeforecheckoutevent")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div>
                                <div className="row gx-3">
                                    <label htmlFor="phone" class="mb-1">
                                        Phone
                                    </label>
                                    <div className="col form-group mb-3 form-group__phone-number">
                                        <select
                                            name=""
                                            id=""
                                            className="form-control"
                                            onChange={event => setPhonePrefixCreateAddress(event.currentTarget.value)}
                                        >
                                            {PHONE_NUMBER_CODE.map(v => (
                                                <option selected={v == phonePrefixCreateAddress} value={v}>
                                                    {v}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            name="phone"
                                            id="phone"
                                            className="form-control"
                                            placeholder="Phone"
                                            value={phoneCreateAddress}
                                            onInput={e => {
                                                setPhoneCreateAddress(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="gender" class="mb-1">
                                    {t("gender")}
                                </label>
                                <div className="col form-group mb-3">
                                    <select
                                        name="gender"
                                        class="form-control"
                                        id="gender"
                                        value={gender}
                                        onChange={e => {
                                            setGender(e.target.value);
                                        }}
                                    >
                                        <option value="" selected disabled>
                                            {t("choosegender")}
                                        </option>
                                        <option value="male">{t("male")}</option>
                                        <option value="female">{t("female")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <label htmlFor="birthdate" class="mb-1">
                                    {t("birthdate")}
                                </label>
                                <div className="col form-group mb-3">
                                    <input
                                        type="date"
                                        name="birthdate"
                                        className="form-control"
                                        value={birthDate}
                                        onChange={e => {
                                            setBirthDate(e.target.value);
                                        }}
                                        id="birthdate"
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            disabled={phoneCreateAddress.length < 9 || !gender || !birthDate}
                            type="button"
                            className="send-button"
                            onClick={() => {
                                doUpdateProfile();
                            }}
                        >
                            {t("save")}
                        </button>
                    </Modal.Footer>
                </Modal>
                {/* End of Modal Confirm */}

                <h2 className="title">Contact Detail</h2>
                <hr />
                <form action="">
                    <div className="form-row">
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                value={fullName}
                                onInput={event => {
                                    setFullname(event.currentTarget.value);
                                }}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Email Address"
                            value={email}
                            onInput={event => {
                                setEmail(event.currentTarget.value);
                            }}
                            disabled
                        />
                    </div>
                    <br />
                    <div
                        style={{ marginLeft: "12px", marginRight: "-7px" }}
                        className="form-group form-group__phone-number"
                    >
                        <label htmlFor="phone_code" className="any-code">
                            Any Code*
                        </label>
                        <select
                            name="phone_code"
                            id="phone_code"
                            className="form-control form-control__phone-code"
                            disabled
                        >
                            {PHONE_NUMBER_CODE.map(v => (
                                <option selected={v == phonePrefix} value={v}>
                                    {v}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            value={phone}
                            onInput={event => {
                                setPhone(event.currentTarget.value);
                            }}
                            disabled
                        />
                    </div>

                    <br />
                    <div className="form-group">
                        <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            className="form-control"
                            placeholder="Message Box"
                            value={message}
                            onInput={event => {
                                setMessage(event.currentTarget.value);
                            }}
                        />
                    </div>
                </form>
            </div>
            <div
                style={{
                    position: "fixed",
                    zIndex: "100",
                    bottom: "0px",
                    left: "0px",
                    background: "#FFF",
                    width: "100%"
                }}
            >
                <table cellPadding={"10px"} width={"100%"}>
                    <tr>
                        <td>{formater.format(ticketObj.price * amountTicket)}</td>
                        <td
                            onClick={() => {
                                localStorage.setItem(
                                    "temp_event_book",
                                    JSON.stringify({
                                        message: message
                                    })
                                );

                                setActivedIndexState(1);
                            }}
                            style={{ background: "#10143F", color: "#FFF", textAlign: "center" }}
                            width={"50%"}
                        >
                            Continue Payment
                        </td>
                    </tr>
                </table>
            </div>
        </>
    );
}
