import React, { useEffect, useState } from "react";
import "./style.css";
import "./list.css";
import { useNavigate } from "react-router-dom";
import Height from "../../mobile-componen/Height";
import axios, { AxiosResponse } from "axios";
import baseUrl from "../../config/baseUrl";
import { itProduct } from '../../interface/itProduct';
import Loading from "../../mobile-componen/Loading";
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { FaChevronCircleRight } from "react-icons/fa";
import { itKategori } from "../../interface/itKategori";
import axiosInstansce from "../../utils/AxiosInstance";
const MenuUtama: React.FC = () => {
    const navigate = useNavigate();
    const [aktifKategori, setAktifKategori] = useState<string>('');
    const [kategori, setKategori] = useState<itKategori[]>([]);
    const [isSearch, setIsSearch] = useState(false);
    const [data, setData] = useState<itProduct[]>([]);
    const [cari, setCari] = useState('');
    const _getData = () => {
        setIsSearch(true);
        axiosInstansce.get(baseUrl('api/product?search=' + cari))
            .then((respon: AxiosResponse<any, any>) => {
                setData(respon.data.data);
                setIsSearch(false);
            })
    }
    const _getKategori = () => {
        axiosInstansce.get(baseUrl('api/product-category'))
            .then((respon) => {
                setKategori(respon.data.data);
            })
    }

    useEffect(() => {
        _getKategori();
    }, [])
    useEffect(() => {
        if (cari != "") {
            setIsSearch(true);
        }
        const c = setTimeout(() => {
            _getData();
        }, 2000);
        return () => clearTimeout(c);

    }, [cari])

    return (<>
        <Height height={50} />
        <div style={{ textAlign: "right" }}>
            <button onClick={() => {
                navigate('/');
            }} className="btn">
                <img style={{}} src="/icons/close.svg" />
            </button>
        </div>
        <div style={{ padding: "10px" }}>
            <img style={{ position: "absolute", right: "20px", marginTop: "10px", textAlign: "right", width: "20px" }} src={isSearch ? "https://i.gifer.com/ZZ5H.gif" : "/icons/setting.svg"} />

            <input onChange={(e) => {
                setCari(e.target.value);
            }} placeholder="Search by brand, category, product name" style={{ paddingLeft: "40px", borderRadius: "20px" }} type="text" className="form-control" />
            <img style={{ marginTop: "-65px", marginLeft: "10px" }} src="/icons/search.min.svg" />
        </div>
        <div style={{ padding: "10px", marginTop: "20px" }}>
            {kategori.map((list, index) => (
                <button onClick={() => {
                    setAktifKategori(list.name);
                    setCari(list.name);
                }} style={{ border: "1px solid #DFDFDF", margin: "5px", ...list.name == aktifKategori && { background: "#FDCA61" } }} className="btn">{list.name}</button>
            ))}
        </div>
        <div>
            <div style={{ paddingLeft: "10px" }}>Hasil pencarian</div>
            <div className="p-3">
                <table width={"100%"}>
                    <tbody>
                        {data.map((list, index) => (
                            <tr id="clist" onClick={() => {

                                navigate('/shop/' + list.id);
                            }}>
                                <td width={"100px"}>
                                    <div style={{ height: "80px", width: "80px", overflow: "hidden" }}>
                                        <img style={{ width: "80px", height: "80px", objectFit: "cover" }} src={list.images[0]} />
                                    </div>
                                    <Height height={10} />

                                </td>

                                <td>
                                    <div style={{ fontWeight: "bold" }}> {list.name}</div>
                                    <div style={{ opacity: "0.5" }}>Rp. {Number(list.sale_price).toLocaleString()}</div>
                                </td>
                                <td width={"20px"} style={{ textAlign: "right" }}>
                                    <FaChevronCircleRight style={{ opacity: "0.5" }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



        </div>
    </>);
}

export default MenuUtama