import { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../../components/general/breadcrumb/BreadCrumbComponent";
import ContainerComponent from "../../../components/general/container/ContainerComponent";
import "./event-booking.scss";
import StepperComponent from "../../../components/pages/event/booking/stepper/StepperComponent";
import FormBookComponent from "../../../components/pages/event/booking/form-book/FormBookComponent";
import FormPaymentComponent from "../../../components/pages/event/booking/form-payment/FormPaymentComponent";
import TicketBookingSummaryComponent from "../../../components/pages/event/booking/ticket-booking-summary/TicketBookingSummaryComponent";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AuthUserContext } from "../../../context/AuthUserContext";
import { LoadingContext } from "../../../context/LoadingContext";
import Api from "../../../utils/Api";
import { FaArrowLeft } from "react-icons/fa";
import { CurrencyContext } from "../../../context/CurrencyContext";

export default function EventBooking() {
    /**
     * Hooks
     *
     */
    const { currency } = useContext(CurrencyContext);
    const formater = new Intl.NumberFormat(currency == "id" ? "id-ID" : "en-EN", {
        style: "currency",
        currency: currency == "id" ? "IDR" : "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    });
    const { pathname } = useLocation();
    const { id, ticket } = useParams();
    const [searchParams] = useSearchParams();

    /**
     * Context
     *
     */
    const { user } = useContext(AuthUserContext);
    const { setLoading } = useContext(LoadingContext);

    /**
     * Main State
     *
     */
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [eventDetailObj, setEventDetailObj] = useState({});

    /**
     * Stepper State
     *
     */
    const [arrFormStepStates, setArrFormStepStates] = useState([]);
    const [activedIndexState, setActivedIndexState] = useState(0);

    const [amount, setAmount] = useState(0);
    useEffect(() => {
        loadBreadcrumbs();
        loadArrFormStepStates();
        loadEventDetailObj(id);

        changeStateWhenBookingId();
    }, []);

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const loadArrFormStepStates = () => {
        setArrFormStepStates(["Book", "Payment", "E-Ticket"]);
    };

    const changeStateWhenBookingId = () => {
        if (searchParams.get("booking_id")) {
            setActivedIndexState(2);
        }
    };

    const loadBreadcrumbs = () => {
        setBreadcrumbs([
            {
                label: "Home",
                url: "/"
            },
            {
                label: "Event",
                url: "/event"
            },
            {
                label: "Fashion Week 2023",
                url: "/event/fashion-week-2023"
            },
            {
                label: "Booking"
            }
        ]);
    };

    const [total, setTotal] = useState(0);
    const loadEventDetailObj = id => {
        if (id) {
            setLoading(true);
            Api.get("/event/" + id).then(res => {
                setEventDetailObj(res.data.data);
                setLoading(false);
                setTotal(parseInt(res.data.data.details[0].price));
            });
        }
    };

    const navigate = useNavigate();
    useEffect(() => {
        const c = window.localStorage.getItem("amount_ticket");
        setAmount(parseInt(c));
    }, []);
    return (
        <>
            <nav
                style={{
                    padding: "14px",
                    position: "fixed",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    zIndex: "300",
                    background: "#fff"
                }}
            >
                <div style={{ height: "30px" }} />
                <table>
                    <tbody>
                        <tr>
                            <td valign="middle">
                                <button
                                    onClick={() => {
                                        navigate("/event");
                                    }}
                                    className="btn"
                                >
                                    <FaArrowLeft />
                                </button>
                            </td>
                            <td width={"10px"} />
                            <td valign="middle">Ticket List</td>
                        </tr>
                    </tbody>
                </table>
            </nav>
            <div className="event-booking-container">
                <ContainerComponent>
                    {activedIndexState == 2 ? (
                        <>
                            <StepperComponent
                                activedIndexState={activedIndexState}
                                steps={arrFormStepStates}
                                setActivedIndexState={setActivedIndexState}
                            />
                        </>
                    ) : null}

                    <TicketBookingSummaryComponent
                        bookingCode={searchParams.get("booking_id")}
                        ticketId={ticket}
                        event={eventDetailObj}
                        activedIndexState={activedIndexState}
                    />

                    {activedIndexState != 2 ? (
                        <>
                            <StepperComponent
                                activedIndexState={activedIndexState}
                                steps={arrFormStepStates}
                                setActivedIndexState={setActivedIndexState}
                            />

                            <div className="stepper-content-wrapper">
                                {activedIndexState == 0 ? (
                                    <FormBookComponent
                                        ticketId={ticket}
                                        event={eventDetailObj}
                                        setActivedIndexState={setActivedIndexState}
                                        user={user}
                                    />
                                ) : null}
                                {activedIndexState == 1 ? (
                                    <FormPaymentComponent
                                        ticketId={ticket}
                                        event={eventDetailObj}
                                        setActivedIndexState={setActivedIndexState}
                                    />
                                ) : null}
                            </div>
                        </>
                    ) : null}
                </ContainerComponent>
            </div>
        </>
    );
}
