import React, { FormEvent, useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { cp } from "fs";
import axiosInstansce from "../../../utils/AxiosInstance";
import { AxiosResponse } from "axios";
import { itUser } from "../../../interface/itUser";
import { useNavigate } from "react-router-dom";

type itPassword = {
    value: string,
    status: boolean,
}
const ChangePassword: React.FC = () => {
    const navigate = useNavigate();
    const [cPassword, setCPassword] = useState<itPassword>({
        value: "",
        status: false,
    })
    const [nPassword, setNPassword] = useState<itPassword>({
        value: "",
        status: false,
    })
    const [rnPassword, setRnPassword] = useState<itPassword>({
        value: "",
        status: false,
    })
    const [user, setUser] = useState<itUser>();
    const _getProfil = () => {
        axiosInstansce.get('user')
            .then((respon: AxiosResponse<any, any>) => {
                setUser(respon.data.data);
            })
    }

    const _changePassword = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const c: boolean = window.confirm('Apakah anda mengganti password ?');
        if (c)
            axiosInstansce.post('change-password', {
                email: user?.email,
                password: nPassword.value,
                password_confirmation: rnPassword.value,
            })
                .then((respon: AxiosResponse<any, any>) => {
                    if (respon.data.errorCode == 0) {
                        alert('Password berhasil di ganti');
                        navigate(-1);

                    }
                });
    }

    useEffect(() => {

        return () => {
            _getProfil();
        };
    }, []);

    return (<>
        <Navbar title="Change Password" />
        <Height height={95} />
        <form onSubmit={_changePassword}>

            <div className="p-3 flex">
                <input
                    onChange={(e) => setCPassword({ ...cPassword, value: e.target.value })}
                    className="flex-auto"
                    style={{ width: "100%", padding: "5px" }}
                    type={cPassword.status ? "text" : "password"}
                    id="cInput"
                    placeholder="Current Password" />
                <button onClick={() => {
                    setCPassword({ ...cPassword, status: !cPassword.status })
                }} type="button" className="btn">
                    {cPassword.status ? <IconEyeOff /> : <IconEye />}
                </button>
            </div>
            <div style={{ height: "2px", background: "#AFAFAF", opacity: "0.1" }}></div>
            <div className="p-3 flex">
                <input
                    onChange={(e) => setNPassword({ ...nPassword, value: e.target.value })}
                    className="flex-auto"
                    style={{ width: "100%", padding: "5px" }}
                    type={nPassword.status ? "text" : "password"}
                    id="cInput"
                    placeholder="New Password" />
                <button
                    onClick={() => {
                        setNPassword({ ...nPassword, status: !nPassword.status })
                    }}
                    type="button" className="btn">
                    {nPassword.status ? <IconEyeOff /> : <IconEye />}
                </button>
            </div>
            <div style={{ height: "2px", background: "#AFAFAF", opacity: "0.1" }}></div>
            <div className="p-3 flex">
                <input
                    className="flex-auto"
                    onChange={(e) => setRnPassword({ ...rnPassword, value: e.target.value })}
                    style={{ width: "100%", padding: "5px" }}
                    type={rnPassword.status ? "text" : "password"}
                    id="cInput"
                    placeholder="Confirm Current Password" />
                <button
                    onClick={() => {
                        setRnPassword({ ...rnPassword, status: !rnPassword.status })
                    }}
                    type="button" className="btn">
                    {rnPassword.status ? <IconEyeOff /> : <IconEye />}
                </button>
            </div>
            <div className="p-3">
                <button className="btn bg-sky-500 text-white" style={{ width: "100%" }} >Update Password</button>
            </div>

        </form>

    </>);
}

export default ChangePassword;