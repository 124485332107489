import React, { FormEvent, useEffect, useState } from "react";
import Navbar from "../../../mobile-componen/Navbar";
import Height from "../../../mobile-componen/Height";
import { AxiosResponse } from "axios";
import axiosInstansce from "../../../utils/AxiosInstance";
import { itProfil } from "./MyProfil";

const GantiEmail: React.FC = () => {
    const [email, setEMail] = useState('');
    const [no_hp, setNo_hp] = useState('');
    const [jenisKelamin, setJenisKelamin] = useState('male');

    const [name, setName] = useState<string>('');
    const [birdhdate, setBirdhdate] = useState('')


    const [isLoading, setLoading] = useState(false);
    const _getProfil = () => {
        axiosInstansce.get('/user')
            .then((respon: AxiosResponse<any, any>) => {
                const data: itProfil = (respon.data.data);
                setName(data.name);
                setJenisKelamin(data.gender)
                setBirdhdate(data.birthDate)
                setEMail(data.email)
                setNo_hp(data.no_hp)
            })
    }

    const _update = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        axiosInstansce.post('user/update', {
            name: name,
            birth_date: birdhdate,
            gender: jenisKelamin,
            email: email,
            no_hp: no_hp,

        }).then((respon: AxiosResponse<any, any>) => {
            if (respon.data.message == "success") {
                alert('Profil berhasil di update');

            }
            setLoading(false);
        })
    }

    useEffect(() => {

        return () => {
            _getProfil()
        };
    }, []);
    return (<>
        <Navbar title="Ubah Email" />
        <Height height={90} />
        <form onSubmit={_update}>
            <div className="flex">
                <label className="p-3">Email</label>
                <input
                    onChange={(e) => {
                        setEMail(e.target.value)
                    }}
                    required
                    value={email}
                    autoComplete="off"
                    style={{ textAlign: "right" }} type="email"
                    placeholder="Masukkan email..."
                    className="flex-auto"
                    id="cInput" />
            </div>
            <div className="p-3">
                <button className="btn btn-primary" {...isLoading && { disabled: true, }} style={{ width: "100%" }}>Update Data</button>
            </div>
        </form>
    </>);
}

export default GantiEmail;