import ContainerComponent from "../../../components/general/container/ContainerComponent";
import "./register.scoped.scss";
import RegisterIllustration from "./../../../images/register/RegisterIllustration.png";
import Checkbox from "react-custom-checkbox";
import { Link, useNavigate, useLocation } from "react-router-dom";
import GoogleIcon from "./../../../images/icons/google-icon.png";
import FacebookIcon from "./../../../images/icons/facebook-icon.png";
import { IconEyeOff } from "@tabler/icons-react";
import Api from "../../../utils/Api";
import { useContext, useEffect, useRef, useState } from "react";
import ApiErrorHandling from "../../../utils/ApiErrorHandling";
import ModalConfirmRegisterComponent from "../../../components/pages/register/index/ModalConfirmRegisterComponent";
import { LoadingContext } from "../../../context/LoadingContext";
import { useTranslation } from "react-i18next";
import Navbar from "../../../mobile-componen/Navbar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import Height from "../../../mobile-componen/Height";
export default function RegisterIndex() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    /**
     * Hooks
     *
     */
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    /**
     * Context
     *
     */
    const { setLoading } = useContext(LoadingContext);

    /**
     * Main State
     *
     */
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [errorObj422, setErrorObj422] = useState({});
    const inputConfirmPasswordRef = useRef();
    const inputPasswordRef = useRef();
    const [buttonEnabledMode, setButtonEnabledMode] = useState(false);
    const [modalConfirmRegistrationShow, setModalConfirmRegistrationShow] = useState(false);
    const [googleLoginUrl, setGoogleLoginUrl] = useState("");

    useEffect(() => {
        setLoading(true);
        Api.get("/auth?provider=google", {
            provider: "google",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        })
            .then(response => {
                return response.data;
            })
            .then(data => setGoogleLoginUrl(data.url))
            .catch(error => console.error(error))
            .finally(() => setLoading(false));
    }, []);

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const doActionRegister = () => {
        handleClose(false);
        setErrorObj422({});
        setLoading(true);

        Api.post("/register", {
            name: fullName,
            email: email,
            password: password,
            password_confirmation: passwordConfirmation
        })
            .then(res => {
                if (res) {
                    localStorage.setItem("emailVerification", email);
                    return navigate("/email-verification");
                }
            })
            .catch(err => {
                if (err.request.status == 422) {
                    handleShow2();
                }
                //  ApiErrorHandling.handlingErr(err, [setErrorObj422]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const toggleShowHideInput = (inputRef, evt) => {
        const inputMode = inputRef.current.getAttribute("type");

        inputRef.current.setAttribute("type", inputMode == "text" ? "password" : "text");
        evt.target.closest("button").innerHTML =
            inputMode == "text"
                ? '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye-off" width="30" height="30" viewBox="0 0 24 24" stroke-width="2" stroke="#999999" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" /><path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" /><path d="M3 3l18 18" /></svg>'
                : '<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-eye" width="30" height="30" viewBox="0 0 24 24" stroke-width="2" stroke="#999999" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" /><path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" /></svg>';
    };

    return (
        <>
            <Navbar title="Sign Up" />
            <div style={{ marginTop: "-100px", paddingRight: "40px" }}>
                <ContainerComponent>
                    <div className="login-section">
                        <ModalConfirmRegisterComponent
                            modalShow={modalConfirmRegistrationShow}
                            setModalShow={setModalConfirmRegistrationShow}
                            callbackConfirm={doActionRegister}
                        />

                        <div className="left only-desktop">
                            <div className="inner">
                                <img src={RegisterIllustration} alt="" />
                            </div>
                        </div>
                        <div className="right">
                            <div className="inner">
                                <div className="sign-is-socmed only-desktop">
                                    <div className="button-wrap">
                                        <a href={googleLoginUrl}>
                                            <button>
                                                <img src={GoogleIcon} alt="" /> Sign in with Google
                                            </button>
                                        </a>
                                        <button>
                                            <img src={FacebookIcon} alt="" /> Sign in with Facebook
                                        </button>
                                    </div>
                                    <p>- OR -</p>
                                </div>
                                <form action="">
                                    <div className="form-group">
                                        <label htmlFor="name">Full Name</label>
                                        <input
                                            style={{ padding: "20px", fontSize: "16px" }}
                                            className={`${errorObj422.name ? "is-invalid" : ""}`}
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Full Name"
                                            value={fullName}
                                            onChange={e => {
                                                setFullName(e.target.value);
                                            }}
                                        />
                                        {errorObj422.name ? (
                                            <span className="text-danger">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-info-circle"
                                                    width="18"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                    <path d="M12 9h.01" />
                                                    <path d="M11 12h1v4h1" />
                                                </svg>
                                                {errorObj422.name}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            style={{ padding: "20px", fontSize: "16px" }}
                                            className={`${errorObj422.email ? "is-invalid" : ""}`}
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                        {errorObj422.email ? (
                                            <span className="text-danger">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    classNam="icon icon-tabler icon-tabler-info-circle"
                                                    width="18"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                    <path d="M12 9h.01" />
                                                    <path d="M11 12h1v4h1" />
                                                </svg>
                                                {errorObj422.email}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            style={{ padding: "20px", fontSize: "16px" }}
                                            ref={inputPasswordRef}
                                            className={`${errorObj422.password ? "is-invalid" : ""}`}
                                            type="password"
                                            name="password"
                                            id="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                            }}
                                        />
                                        {errorObj422.password ? (
                                            <span className="text-danger">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-info-circle"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                    <path d="M12 9h.01" />
                                                    <path d="M11 12h1v4h1" />
                                                </svg>
                                                {errorObj422.password}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                        <button
                                            type="button"
                                            tabIndex={-1}
                                            onClick={e => {
                                                toggleShowHideInput(inputPasswordRef, e);
                                            }}
                                            className="show-hide"
                                        >
                                            <IconEyeOff size={30} color="#999" />
                                        </button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password_confirmation">Confirm Password</label>
                                        <input
                                            style={{ padding: "20px", fontSize: "16px" }}
                                            ref={inputConfirmPasswordRef}
                                            className={`${errorObj422.password_confirmation ? "is-invalid" : ""}`}
                                            type="password"
                                            name="password_confirmation"
                                            id="password_confirmation"
                                            placeholder="Password"
                                            value={passwordConfirmation}
                                            onChange={e => {
                                                setPasswordConfirmation(e.target.value);
                                            }}
                                        />
                                        {errorObj422.password_confirmation ? (
                                            <span className="text-danger">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="icon icon-tabler icon-tabler-info-circle"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="2"
                                                    stroke="currentColor"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                    <path d="M12 9h.01" />
                                                    <path d="M11 12h1v4h1" />
                                                </svg>
                                                {errorObj422.password_confirmation}
                                            </span>
                                        ) : (
                                            <></>
                                        )}
                                        <button
                                            type="button"
                                            tabIndex={-1}
                                            onClick={e => {
                                                toggleShowHideInput(inputConfirmPasswordRef, e);
                                            }}
                                            className="show-hide"
                                        >
                                            <IconEyeOff size={30} color="#999" />
                                        </button>
                                    </div>
                                    <div className="form-group remember-me-password">
                                        <div className="form-check" style={{ cursor: "pointer" }}>
                                            <Checkbox
                                                style={{ cursor: "pointer", transform: "translateY(6px)" }}
                                                checked={buttonEnabledMode}
                                                onChange={value => {
                                                    setButtonEnabledMode(value);
                                                }}
                                                borderColor={"#DADADA"}
                                            />
                                            <p className="remember-me-label" htmlFor="remember_me">
                                                By clicking Register, you agree to our Terms, Privacy Policy and Cookie
                                                Policy.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="form-group form-group__button">
                                        <button
                                            className={`button-submit ${buttonEnabledMode ? "enabled" : ""}`}
                                            type="button"
                                            onClick={() => {
                                                handleShow();
                                            }}
                                        >
                                            Create Account
                                        </button>
                                    </div>
                                </form>
                                <p>
                                    Already have an account? <Link to={"/login"}>Log In</Link>
                                </p>
                                <div className="sign-is-socmed only-mobile">
                                    <p>- OR -</p>
                                    <div className="button-wrap">
                                        <a href={googleLoginUrl}>
                                            <button>
                                                <img src={GoogleIcon} alt="" /> Sign in with Google
                                            </button>
                                        </a>
                                        <button>
                                            <img src={FacebookIcon} alt="" /> Sign in with Facebook
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ContainerComponent>
            </div>

            <Modal style={{ padding: "50px" }} size="sm" centered show={show} onHide={handleClose}>
                <Modal.Body>
                    <div style={{ textAlign: "right" }}>
                        <button onClick={handleClose} className="btn">
                            x
                        </button>
                    </div>
                    <Height height={40} />
                    <div style={{ textAlign: "center" }}>
                        <div style={{ color: "#F99A1C", fontWeight: "bold", fontSize: "22px" }}>Confirmation</div>
                        <Height height={20} />
                        <div style={{ opacity: "0.5" }}>Apakah data yang di masukkan sudah benar?</div>
                        <Height height={30} />
                        <div>
                            <button onClick={handleClose} style={{ border: "1px solid #4c4c4c" }} className="btn">
                                Change
                            </button>{" "}
                            <button
                                onClick={() => {
                                    doActionRegister();
                                }}
                                style={{ background: "#4C4C4C", color: "#FFF" }}
                                className="btn"
                            >
                                Save
                            </button>
                        </div>
                        <Height height={40} />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal style={{ padding: "50px" }} size="sm" centered show={show2} onHide={handleClose2}>
                <Modal.Body>
                    <div style={{ textAlign: "right" }}>
                        <button onClick={handleClose2} className="btn">
                            x
                        </button>
                    </div>
                    <Height height={40} />
                    <div style={{ textAlign: "center" }}>
                        <div style={{ color: "#F99A1C", fontWeight: "bold", fontSize: "22px" }}>Confirmation</div>
                        <Height height={20} />
                        <div style={{ opacity: "0.5" }}>
                            Maaf. Email yang anda masukkan
                            <br />
                            sudah terdaftar.
                        </div>
                        <Height height={30} />
                        <div>
                            <button onClick={handleClose2} style={{ border: "1px solid #4c4c4c" }} className="btn">
                                Change
                            </button>{" "}
                        </div>
                        <Height height={40} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
