import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { changeLanguage } from "../../../translations/i18n";
import { withTranslation } from "react-i18next";
import "./navbar-home.scoped.scss";
import ContainerComponent from "../container/ContainerComponent";
import BrandLogo from "./../../../images/brands/logo.png";
import { NavLink, useLocation, Link } from "react-router-dom";
import {
    IconAlignLeft,
    IconArrowRight,
    IconMapPin,
    IconSearch,
    IconShoppingCartFilled,
    IconUserCircle,
    IconX
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import StringUtil from "../../../utils/StringUtil";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Api from "../../../utils/Api";
import SidebarComponent from "../sidebar/SidebarComponent";
import { AuthUserContext } from "../../../context/AuthUserContext";
import { LanguageContext } from "../../../context/LanguageContext";
import { SiteSettingContext } from "../../../context/SiteSettingContext";
import NoPhoto from "./../../../images/icons/no-photo.png";
import FloatingCartComponent from "../floating-cart/FloatingCartComponent";
import SearchComponent from "../search/SearchComponent";
import { FaSearch, FaWhatsapp, FaWhatsappSquare } from "react-icons/fa";

const useReactPath = () => {
    const [path, setPath] = useState(window.location.pathname);
    const listenToPopstate = () => {
        const winPath = window.location.pathname;
        setPath(winPath);
    };
    useEffect(() => {
        window.addEventListener("popstate", listenToPopstate);
        return () => {
            window.removeEventListener("popstate", listenToPopstate);
        };
    }, []);
    return path;
};

function NavbarHomeComponent({ t }) {
    /**
     * Hooks
     *
     */
    const path = useReactPath();

    /**
     * Refs
     *
     */
    const navRef = useRef();
    const actionsWrapperRef = useRef();

    /**
     * Context
     *
     */
    const { user } = useContext(AuthUserContext);
    const { siteSetting } = useContext(SiteSettingContext);

    /**
     * Main State
     *
     */
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [aboutUs, setAboutUs] = useState({});
    const { language, setLanguage } = useContext(LanguageContext);

    useEffect(() => {
        loadBrands();
        loadCategories();
        loadAboutUs();
    }, []);

    const loadBrands = () => {
        Api.get("/brand?is_valid=true").then(res => {
            if (res) {
                setBrands(res.data.data);
            }
        });
    };

    const loadCategories = () => {
        Api.get("/product-category").then(res => {
            if (res) {
                setCategories(res.data.data);
            }
        });
    };

    const loadAboutUs = () => {
        Api.get("/about-us").then(res => {
            setAboutUs(res.data.data[0]);
        });
    };

    const handleLanguageChange = lng => {
        changeLanguage(lng);
        setLanguage(lng);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const [scroll, setScroll] = useState({
        x: 0,
        y: 0
    });
    useEffect(() => {
        const handleScroll = () => {
            const x = window.scrollX;
            const y = window.scrollY;
            setScroll({
                x: x,
                y: y
            });
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const navigate = useNavigate();
    return (
        <>
            <nav
                {...(scroll.y > 100
                    ? {
                          style: {
                              background: "#FFF",
                              position: "fixed",
                              width: "100%",
                              zIndex: "1000",
                              paddingTop: "25px"
                          }
                      }
                    : {
                          style: {
                              top: "60px",
                              position: "fixed",
                              marginBottom: "-100px",
                              zIndex: "1000",
                              left: "0px",
                              width: "100%",
                              paddingTop: "30px"
                          }
                      })}
                className="navbar-olshop"
            >
                <div style={{ padding: "10px" }}>
                    <div style={{ height: "10px" }} />
                    <table border={"0px"} width={"100%"}>
                        <tr>
                            <td valign="middle">
                                <button
                                    onClick={() => {
                                        navigate("/menu-utama");
                                    }}
                                    style={{
                                        width: "100%",
                                        background: "#FFF",
                                        border: "1px solid #DFDFDF",
                                        padding: "10px",
                                        display: "flex",
                                        borderRadius: "20px"
                                    }}
                                >
                                    <FaSearch />{" "}
                                    <span style={{ fontSize: "10px", paddingLeft: "10px" }}>Search by namex.... </span>
                                </button>
                            </td>
                            <td valign="middle" width={"140px"} style={{ textAlign: "right" }}>
                                <button
                                    onClick={() => {
                                        navigate("/notifikasi-page");
                                    }}
                                    className="btn btn-sm"
                                >
                                    <img
                                        style={{ color: "red" }}
                                        src={`/icons/notif${scroll.y > 100 ? "_" : ""}.svg`}
                                    />
                                </button>{" "}
                                <button
                                    onClick={() => {
                                        navigate("/shopping/cart");
                                    }}
                                    className="btn btn-sm"
                                >
                                    <img src={`/icons/cart${scroll.y > 100 ? "_" : ""}.svg`} />
                                </button>
                                <a
                                    href="https://api.whatsapp.com/send?phone=6281804058981&text=Terima%20Kasih%20and%20telah%20menghubungi%20Luxuryhub.%20Silahkan%20tinggalkan%20pesan%20untuk%20dapat%20admin%20bantu"
                                    className="btn btn-sm"
                                >
                                    <FaWhatsapp size={26} color={scroll.y < 100 ? "#FFFFFF" : "#333333"} />
                                </a>
                            </td>
                        </tr>
                    </table>
                </div>
            </nav>
            <div>
                <div className="nav-container" ref={navRef}>
                    <ContainerComponent />
                </div>

                <SidebarComponent
                    sidebarOpen={sidebarOpen}
                    toggleSidebar={toggleSidebar}
                    categories={categories}
                    brands={brands}
                    aboutUs={aboutUs}
                />
                {searchOpen ? (
                    <SearchComponent setSearchOpen={setSearchOpen} brands={brands} categories={categories} />
                ) : null}
            </div>
        </>
    );
}

export default withTranslation()(NavbarHomeComponent);
