import React, { useEffect, useState } from "react";
import Navbar from "../../mobile-componen/Navbar";
import Height from "../../mobile-componen/Height";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Setting: React.FC = () => {
    const navigate = useNavigate();
    const [lang, setLang] = useState<string>('');
    useEffect(() => {
        return () => {
            const c: string = window.localStorage.getItem('selectedLanguage') ?? "";
            setLang(c);
        }
    }, [])
    return (<>
        <Navbar title="Setting" back="/profile" />
        <Height height={85} />
        <div className=" bg-slate-100 p-3">My Account</div>
        <div className=" divide-y divide-slate-200">
            <div onClick={() => {
                navigate('/setting/account-security')
            }} className=" p-3 flex active:bg-red-200">
                <div className="flex-1">
                    Account & Security
                </div>
                <FaChevronRight />
            </div>
            <div
                onClick={() => {
                    navigate('/profile/address');
                }}
                className="p-3 active:bg-red-200 flex">
                <div className="flex-1">
                    My Address
                </div>
                <FaChevronRight />
            </div>
        </div>
        <div className=" bg-slate-100 p-3">Setting</div>
        <div className=" divide-y divide-slate-200">
            <div onClick={() => navigate('/notifikasi-setting')} className="p-3 flex  active:bg-red-200">
                <div className="flex-1">
                    Notification Setting
                </div>
                <FaChevronRight />
            </div>
            <div onClick={() => navigate('/privacy-setting')} className="p-3 flex  active:bg-red-200">
                <div className="flex-1">
                    Privacy Setting
                </div>
                <FaChevronRight />
            </div>
            <div onClick={() => {
                navigate('/setting/language')
            }} className="p-3 flex  active:bg-red-200">
                <div className="flex-1">
                    Language
                    <div style={{ marginTop: "-5px" }}>

                        <small style={{ opacity: ".5" }} className=" shadow-fuchsia-100">{lang == "en" ? "English UK" : "Bahasa Indonesia"}</small>
                    </div>

                </div>
                <FaChevronRight />
            </div>

        </div>
        <div className=" bg-slate-100 p-3">Help</div>
        <div className=" divide-y divide-slate-200">

            <div onClick={() => {
                navigate('/policy')
            }} className="p-3 flex  active:bg-red-200">
                <div className="flex-1">
                    Return Police
                </div>
                <FaChevronRight />
            </div>
            <div onClick={() => {
                navigate('/privacy-policy')
            }} className="p-3 flex  active:bg-red-200">
                <div className="flex-1">
                    Privacy Police
                </div>
                <FaChevronRight />
            </div>
            <div onClick={() => {
                navigate('/term-and-conditions')
            }} className="p-3 flex  active:bg-red-200">
                <div className="flex-1">
                    Term and Condition
                </div>
                <FaChevronRight />
            </div>
            <div onClick={() => {
                navigate('/contact')
            }} className="p-3 flex  active:bg-red-200">
                <div className="flex-1">
                    Contact
                </div>
                <FaChevronRight />
            </div>
            {/* <div className="p-3 flex">
                <div className="flex-1">
                    Request Account Deletion
                </div>
                <FaChevronRight />
            </div> */}

        </div>
        <Height height={20} />
        <div className="p-4">
            <button style={{ width: "100%", }} className="btn btn-block bg-slate-600 text-white">Logout</button>
        </div>


    </>);
}

export default Setting;